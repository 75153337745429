<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-24-podepsat</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-podepsat" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="177259240"
        transform="translate(2.000000, 2.000000)"
        fill-rule="nonzero"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          d="M9.06434751,6.51428571 L7.62705622,7.99075556 L0.999968802,11.1486591 C1.19914105,12.6522488 1.19914105,14.0914723 0.999968802,15.4663294 C0.800796551,16.8411866 0.467473617,18.0190768 0,19 C1.3241153,18.4605574 2.73313445,18.1119972 4.22705742,17.9543193 C5.7209804,17.7966414 6.97491786,17.8550908 7.98886979,18.1296673 L11.2721968,11.1486591 L12.4857143,10.0821169"
          id="Path-27"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path d="M0,19 L3.8,15.2" id="Path-28" stroke-linecap="round" stroke-linejoin="round"></path>
        <circle id="Oval-10" cx="5.42857143" cy="13.5714286" r="1"></circle>
        <path
          d="M7.84948974,8.0989914 C7.44785008,7.23451426 7.53114343,6.47704785 8.09936979,5.82659217 C8.95170933,4.85090864 10.9714204,5.79155632 12.5041925,7.32080626 C14.0369645,8.8500562 14.503887,10.8123233 13.7819822,11.6116597 C13.3007124,12.1445506 12.546188,12.0343824 11.5184091,11.2811549"
          id="Path-29"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M9.77142857,4.1357609 C11.0007395,3.43282376 12.3467562,3.83361986 13.8094787,5.33814923 C15.2722012,6.84267859 15.5809192,8.13948599 14.7356327,9.22857143"
          id="Path-30"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path d="M11.4,3.8 L15.2,0" id="Path-38" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M15.7428571,7.05714286 L19,3.8" id="Path-40" stroke-linecap="round" stroke-linejoin="round"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoSignature',
}
</script>

<style lang="scss" scoped></style>
